<template>
  <div>
    <b-form method="post" class="register">
      <div class="row mb-4">
        <div class="col-12">
          <b-alert show
            >رقم هاتفك الذي سجلت به هو:
            <strong>{{ phoneNumber }}</strong></b-alert
          >
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <p>
            <label for="password1">
              كلمة المرور الجديدة
              <i class="hi-lock"></i>
              <b-form-input
                @keydown.space="event => event.preventDefault()"
                id="password1"
                v-model="password"
                class="input-text"
                :class="{ 'has-error': $v.password.$error }"
                :type="passwordFieldType"
                placeholder="ادخل كلمة المرور"
                @blur="$v.password.$touch()"
              />
              <i
                class="show-hide-password"
                :class="{
                  'hi-eye': passwordFieldType === 'password',
                  'hi-eye-crossed': passwordFieldType === 'text'
                }"
                @click="switchVisibility"
              ></i>
            </label>
            <span
              v-if="submitted && !$v.password.minLength"
              class="invalid-feedback d-block"
              >كلمة المرور يجب أن لا تقل عن 6 أحرف</span
            >
          </p>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  props: ['phoneNumber', 'confirmationCode'],
  data() {
    return {
      password: '',
      submitted: false,
      passwordFieldType: 'password'
    }
  },
  computed: {
    ...mapState('accountState', ['configs']),
    ...mapState('userState', ['spinnerLoading']),
    resetPasswordData() {
      let resetPasswordData = {
        phoneNumber: this.phoneNumber,
        password: this.password,
        confirmationCode: this.confirmationCode
      }
      return resetPasswordData
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)

      // goodPassword: password => {
      //     //a custom validator!
      //     return password.length >= 8 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password);
      // },
    },
    form: ['password']
  },

  methods: {
    ...mapActions('accountState', ['resetPassword']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    validate() {
      this.submitted = true
      this.setSpinnerLoading(true)

      this.$v.form.$touch()
      var isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)

      if (!isValid) {
        this.$toasted.show('من فضلك تأكد من صحة كلمة المرور الجديدة', {
          duration: 2000,
          type: 'error',
          icon: 'fa-times'
        })
        this.setSpinnerLoading(false)
      } else {
        this.resetPassword(this.resetPasswordData)
          .then(res => {
            if (res.data.httpCode === 403) {
              this.$toasted.show(
                'كلمة المرور الجديدة لا يمكن ان تكون نفس كلمة المرور القديمة',
                {
                  duration: 3000,
                  type: 'error',
                  icon: 'fa-close'
                }
              )
            } else {
              this.$toasted.show('تم تغيير كلمةالمرور بنجاح', {
                duration: 2000,
                type: 'success',
                icon: 'fa-check',
                onComplete: () => this.$router.push({ name: 'login' })
              })
            }
            this.setSpinnerLoading(false)
          })
          .catch(() => {
            this.$toasted.show('كلمة المرور الحالية غير مطابقة', {
              duration: 2000,
              type: 'error',
              icon: 'fa-close'
            })
            this.setSpinnerLoading(false)
          })
      }

      return isValid
    }
  }
}
</script>
