<template>
  <div>
    <!-- Titlebar -->
    <div class="parallax-container">
      <div class="parallax titlebar">
        <div id="titlebar">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2>إعادة تعيين كلمة المرور</h2>
                <!-- <span>اشترك معنا و استفد من خدمات الموقع</span> -->
                <!-- Breadcrumbs -->
                <nav id="breadcrumbs">
                  <ul>
                    <li><router-link to="/">الرئيسية</router-link></li>
                    <li>
                      <router-link to="/auth/login">تسجيل دخول</router-link>
                    </li>
                    <li>إعادة تعيين كلمة المرور</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
        <img :src="titleBg" />
      </parallax>
    </div>

    <!-- Container -->
    <div class="container">
      <div class="row">
        <div
          id="login-register-tabs"
          class="col-md-8 col-sm-12 col-md-offset-2 col-sm-offset-0"
        >
          <!--Tab -->

          <div class="my-account style-1 margin-top-30 margin-bottom-30">
            <!-- Forget Password -->
            <form-wizard
              id="register-wizard"
              ref="regWizard"
              shape="circle"
              title
              subtitle
              next-button-text="التالي"
              back-button-text="رجوع"
              finish-button-text="تسجيل"
              step-size="xs"
              color="#3cab74"
              error-color="#e74c3c"
            >
              <!-- Step #1 ----------------------------------------------------->
              <tab-content :before-change="() => validateStep('step1')">
                <register-step1 ref="step1" @on-validate="onCheckPhoneNumber">
                </register-step1>
              </tab-content>

              <!-- Step #2 ----------------------------------------------------->
              <tab-content :before-change="() => validateStep('step2')">
                <div id="step-2">
                  <div class="step-title mb-5">
                    <h4 class="submit-section-header">التحقق من رقم الجوال</h4>
                    <p>
                      فضلا قم بإدخال رمز التحقيق المكون من ٤ أرقام المرسل إلى
                      جوالك رقم <strong>{{ finalModel.phoneNumber }}</strong>
                    </p>
                  </div>
                  <register-step2
                    ref="step2"
                    :phone-number="finalModel.phoneNumber"
                    @on-validate="onConfirmPhoneNumber"
                  ></register-step2>
                </div>
              </tab-content>

              <!-- Step #3 ----------------------------------------------------->
              <tab-content :before-change="() => validateStep('step3')">
                <reset-password
                  ref="step3"
                  :phone-number="finalModel.phoneNumber"
                  :confirmation-code="confirmationCode"
                  @on-validate="collectData"
                ></reset-password>
              </tab-content>

              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <b-button
                    v-if="props.activeTabIndex > 0 && !props.isLastStep"
                    variant="primary"
                    @click.prevent="props.prevTab()"
                    >الخطوة السابقة</b-button
                  >
                </div>
                <div class="wizard-footer-right">
                  <b-button
                    v-if="!props.isLastStep"
                    variant="primary"
                    class="wizard-footer-right"
                    @click.prevent="props.nextTab()"
                  >
                    <span>الخطوة التالية</span>
                    <b-spinner
                      v-show="spinnerLoading"
                      small
                      class="mr-2"
                    ></b-spinner>
                  </b-button>

                  <b-button
                    v-else
                    style="float: left !important"
                    variant="primary"
                    class="wizard-footer-right finish-button"
                    @click.prevent="validateStep('step3')"
                  >
                    <span>تعيين كلمة المرور الجديدة</span>
                    <b-spinner
                      v-show="spinnerLoading"
                      small
                      class="mr-2"
                    ></b-spinner>
                  </b-button>
                </div>
              </template>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
    <!-- Container / End -->

    <!-- <div class="container">
          قيم الحقول
          <pre>{{finalModel}}</pre>
        </div> -->

    <div class="margin-top-55"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { FormMixin } from '@/mixins/form.mixin'
import Parallax from 'vue-parallaxy'
//Wizard
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import RegisterStep1 from '@/components/login-register/RegisterStep1.vue'
import RegisterStep2 from '@/components/login-register/RegisterStep2.vue'
import ResetPassword from '@/components/login-register/ResetPassword.vue'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'forget-password',
  layout: 'main',
  components: {
    Parallax,
    FormWizard,
    TabContent,
    RegisterStep1,
    RegisterStep2,
    ResetPassword
  },
  mixins: [FormMixin],
  data() {
    return {
      titleBg: require('../../assets/images/home-parallax-2.jpg'),
      finalModel: {},
      phoneNumber: '',
      phoneData: {},
      password: '',
      confirmationCode: '',
      submitted: false
    }
  },
  computed: {
    ...mapState('accountState', ['status']),
    ...mapState('userState', ['spinnerLoading']),
    isValidPhone() {
      return this.phoneData.valid ? this.phoneData.valid : false
    }
  },

  validations: {
    phoneNumber: {
      required
    },
    password: {
      required
    },
    form: ['phoneNumber', 'password']
  },

  methods: {
    ...mapActions('accountState', [
      'checkPhoneNumberForResetPassword',
      'confirmForgetCode'
    ]),
    ...mapMutations('accountState', ['otpConfirmed']),
    ...mapMutations('userState', ['setSpinnerLoading']),

    validateStep(name) {
      var refToValidate = this.$refs[name]
      return refToValidate.validate()
    },

    collectData(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model)
      }
    },

    onCheckPhoneNumber(phoneNumber) {
      let phone = { phoneNumber: phoneNumber.number.e164.replace(/\+/g, '') }
      this.collectData(phone, true)
      this.setSpinnerLoading(true)
      this.checkPhoneNumberForResetPassword(phone).then(
        (res) => {
          this.confirmationCode = res.data.confirmationCode
          this.$toasted.show('تم إرسال الرقم التأكيدي لهاتفك بنجاح!', {
            duration: 2000,
            type: 'success',
            icon: 'hi-check'
          })
          // Go to next step
          this.$refs.regWizard.changeTab(0, 1)
          this.setSpinnerLoading(false)
        },
        () => {
          this.setSpinnerLoading(false)
          this.$toasted.show(
            'عفواً، الرقم الذي تحاول استرجاع كلمة مروره غير مُسجل',
            {
              duration: 2000,
              type: 'error',
              icon: 'hi-close'
            }
          )
        }
      )
    },

    async onConfirmPhoneNumber(data) {
      try {
        this.collectData(data, true)
        this.setSpinnerLoading(true)
        const res = await this.confirmForgetCode(data)
        if (res.data.success) {
          this.$toasted.show('تم التحقق من رقم هاتفك', {
            duration: 2000,
            type: 'success',
            icon: 'hi-check'
          })
          // this.otpConfirmed(true);
          this.$refs.regWizard.changeTab(1, 2)
        } else {
          this.$toasted.show('رقم التحقق الذي أدخلته غير صحيح', {
            duration: 2000,
            type: 'error',
            icon: 'hi-close'
          })
        }
      } catch {
        this.$toasted.show('رقم التحقق الذي أدخلته غير صحيح', {
          duration: 2000,
          type: 'error',
          icon: 'hi-close'
        })
      } finally {
        this.setSpinnerLoading(false)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login-form {
  width: 400px;
  padding: 25px 15px;
}

div.Masthead:after {
  background-color: rgb(61, 186, 126);
  opacity: 0.9;
}
.margin-top-55 {
  margin-top: 110px !important;
}
</style>
